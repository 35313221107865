import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

export interface ErrorPageProps {
  errorTitle?: string;
  errorDescription?: string;
}

const ErrorPage = ({ errorTitle, errorDescription }: ErrorPageProps) => {
  const params = useParams();
  if (params.errorTitle) {
    errorTitle = params.errorTitle;
  }

  if (params.errorDescription) {
    errorDescription = params.errorDescription;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Stack>
        <Typography variant="h1">{errorTitle}</Typography>
        <Typography variant="h4">{errorDescription}</Typography>
      </Stack>
    </Box>
  );
};

export default ErrorPage;

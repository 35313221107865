import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { LAYOUT } from 'Constants/keys';

const Items = (
  <React.Fragment>
    <ListItemButton component={Link} to={ROUTE_PATHS.LEADERBOARD}>
      <ListItemIcon>
        <LeaderboardIcon />
      </ListItemIcon>
      <ListItemText primary={LAYOUT.LEADERBOARD} />
    </ListItemButton>
    <ListItemButton component={Link} to={ROUTE_PATHS.USERS}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary={LAYOUT.USERS} />
    </ListItemButton>
  </React.Fragment>
);

export default Items;

import { Buffer } from 'buffer';

const UserIdOffset = 6;

export const decodeUserId = (base64Id: string | undefined): string => {
  if (!base64Id) return '';

  // IDs returned by the users query are encoded in base64.
  // Hot Chocolate also prefixes the ID with "User\nd", so after decoding the
  // ID we drop the first 6 characters to remove the prefix.
  const buffer = Buffer.from(base64Id, 'base64');
  return buffer.toString('binary').substring(UserIdOffset, undefined);
};

export const ROUTE_PATHS = {
  WILDCARD: '*',
  PRIVATE: 'private/',
  PRIVATE_ROOT: '/private/',
  BLANK: '',
  ROOT: '/',
  ERROR: 'error/',
  ERROR_TITLE_DESCRIPTION: 'error/:errorTitle/:errorDescription',
  LEADERBOARD: 'leaderboard/',
  USERS: 'users/',
  USERS_USERID: 'users/:userId',
};

import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface UserFieldProps {
  fieldName: string;
  value?: string;
  icon?: ReactNode;
}

const UserField = ({ fieldName, value, icon }: UserFieldProps) => {
  return (
    <Stack direction="row" mb={2}>
      <Typography sx={{ fontWeight: 'bold', width: 300 }}>{fieldName}</Typography>
      <Typography>{value}</Typography>
      {icon}
    </Stack>
  );
};

export default UserField;

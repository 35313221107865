import { Box, CircularProgress } from '@mui/material';

const SplashScreen = () => {
  return (
    <Box flexDirection="column" display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default SplashScreen;

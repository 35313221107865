import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { USER_PROGRESS } from 'Constants/keys';
import { GameProgress, Maybe, UnitProgress } from 'graphql/generated/graphql';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment } from 'react';
import UserField from './UserField';

export interface UserProgressTabProps {
  progress?: Maybe<GameProgress>;
}

function unitAccordion(unit: UnitProgress) {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row">
          <Box width={265}>
            <Typography sx={{ fontWeight: 'bold' }}>{unit.unitName}</Typography>
          </Box>
          <Box ml={2} justifyItems="end">
            {unit.isComplete ? <DoneIcon color="success" /> : <Fragment />}
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box minWidth={600}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="50%">
                  <Typography sx={{ fontWeight: 'bold', width: '200' }}>{USER_PROGRESS.TOPIC_NAME}</Typography>{' '}
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontWeight: 'bold' }}>{USER_PROGRESS.LESSONS_COMPLETED}</Typography>{' '}
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontWeight: 'bold' }}>{USER_PROGRESS.LOCKED}</Typography>{' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unit?.topicProgresses.map((topic) => (
                <TableRow key={topic.topicDefinitionId}>
                  <TableCell>{topic.topicName}</TableCell>
                  <TableCell align="center">{topic.completedLessonCount}</TableCell>
                  <TableCell align="center">{topic.isLocked ? <LockIcon /> : <LockOpenIcon />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const UserProgressTab = ({ progress }: UserProgressTabProps) => {
  return (
    <Box>
      <UserField
        fieldName={USER_PROGRESS.GAME_COMPLETED}
        icon={progress?.isComplete ? <DoneIcon color="success" /> : <CloseIcon />}
      />

      <List>
        {progress?.unitProgresses.map((unit) => (
          <ListItem key={unit.unitDefinitionId} sx={{ pl: '0' }}>
            {unitAccordion(unit)}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserProgressTab;

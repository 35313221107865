import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { USER_ACHIEVEMENTS } from 'Constants/keys';
import { Achievements, BadgeType, Maybe } from 'graphql/generated/graphql';
import UserField from './UserField';

export interface UserAchievementsTabProps {
  achievements?: Maybe<Achievements>;
}

const badgeDescriptions = new Map<BadgeType, string>([
  [BadgeType.Accuracy, 'Accuracy'],
  [BadgeType.Experience, 'Experience'],
  [BadgeType.LessonCompletion, 'Lesson Completion'],
  [BadgeType.Streak, 'Streak'],
  [BadgeType.UnitCompletion, 'Unit Completion'],
]);

const UserAchievementsTab = ({ achievements }: UserAchievementsTabProps) => {
  return (
    <Box>
      <UserField fieldName={USER_ACHIEVEMENTS.EXPERIENCE_POINTS} value={achievements?.experiencePoints.toString()} />
      <UserField fieldName={USER_ACHIEVEMENTS.LONGEST_STREAK} value={achievements?.longestStreak.toString()} />
      <UserField fieldName={USER_ACHIEVEMENTS.CURRENT_STREAK} value={achievements?.currentStreak.toString()} />
      <UserField
        fieldName={USER_ACHIEVEMENTS.LAST_LESSON_COMPLETION}
        value={achievements?.latestLessonCompletionTime?.toLocaleString()}
      />
      <UserField fieldName={USER_ACHIEVEMENTS.PERFECT_LESSONS} value={achievements?.perfectLessons.toString()} />
      <Box mt={2} ml={1} width={370}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={150}>
                <Typography sx={{ fontWeight: 'bold' }}>{USER_ACHIEVEMENTS.BADGE_TYPE}</Typography>
              </TableCell>
              <TableCell width={100} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>{USER_ACHIEVEMENTS.LEVEL}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {achievements?.badges.map((row) => (
              <TableRow key={row.type}>
                <TableCell>{badgeDescriptions.get(row.type)}</TableCell>
                <TableCell align="center">{row.level}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default UserAchievementsTab;

import { Box, CircularProgress } from '@mui/material';
import { User, useUsersWithOffsetPagingQuery } from 'graphql/generated/graphql';
import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { COMMON, USERS } from 'Constants/keys';
import { decodeUserId } from 'Util/userId';

const PAGE_SIZE = 15;

const columns: GridColDef[] = [
  {
    field: 'username',
    headerName: USERS.USERNAME,
    width: 200,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.profile.username,
  },
  { field: 'email', headerName: USERS.EMAIL, width: 300, sortable: true, filterable: true },
  {
    field: 'profile?.institution',
    headerName: USERS.INSTITUTION,
    width: 400,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.profile.institution,
  },
  {
    field: 'id',
    headerName: USERS.ID,
    width: 300,
    sortable: true,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => decodeUserId(params.row.id),
  },
];

const UsersPage = () => {
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useUsersWithOffsetPagingQuery({
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    variables: {
      skip: 0, // start from first item
      take: -1, // retrieve all items
    },
  });

  useEffect(() => {
    if (usersError) {
      navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.ERROR + COMMON.ERROR + '/' + usersError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersError]);

  const [selection, setSelection] = React.useState<GridSelectionModel>([]);

  const navigate = useNavigate();

  if (usersLoading || !usersData || !usersData.usersWithOffsetPaging?.items)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  const users: User[] = usersData.usersWithOffsetPaging?.items;

  return (
    <Box height={1000}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        onSelectionModelChange={(newSelection: GridSelectionModel) => {
          if (newSelection.length > 0) {
            navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.USERS + newSelection[0]);
          }

          setSelection(newSelection);
        }}
        selectionModel={selection}
      />
    </Box>
  );
};

export default UsersPage;

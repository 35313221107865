import { Box } from '@mui/material';
import { USER_PROFILE } from 'Constants/keys';
import { Maybe, Profile } from 'graphql/generated/graphql';
import UserField from './UserField';

export interface UserProfileTabProps {
  profile?: Maybe<Profile>;
}

const UserProfileTab = ({ profile }: UserProfileTabProps) => {
  return (
    <Box>
      <UserField fieldName={USER_PROFILE.USERNAME} value={profile?.username?.toString()} />
      <UserField fieldName={USER_PROFILE.INSTITUTION} value={profile?.institution?.toString()} />
      <UserField fieldName={USER_PROFILE.LAST_LOGIN} value={profile?.lastLoginTime.toLocaleString()} />
      <UserField fieldName={USER_PROFILE.AVATAR_VARIANT} value={profile?.avatarVariant?.toString()} />
      <UserField fieldName={USER_PROFILE.AVATAR_URL} value={profile?.avatarUrl?.toString()} />
    </Box>
  );
};

export default UserProfileTab;

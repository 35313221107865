import { Box } from '@mui/material';
import { LeaderboardEntryWithProfile } from 'graphql/generated/graphql';
import { COMMON, DELETED_USER, DELETED_USER_DISPLAY_NAME, EMPTY_STRING, LEADERBOARD } from 'Constants/keys';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { useNavigate } from 'react-router-dom';

export interface LeaderboardGridRow {
  id: string;
  rank: number;
  username: string;
  institution: string | null | undefined;
  points: number;
}

// The leaderboard is a list of LeaderboardGridRow.
// If an error is encountered while creating the leaderboard, an Error will be thrown.
export interface LeaderboardGridProps {
  // The list of entries to show on the leaderboard. The component will query for the user
  // information of each user on the leaderboard.
  leaderboardEntries: LeaderboardEntryWithProfile[];
}

// Given a list of leaderboard entries sorted from highest to lowest experience,
// and the User data for each of those users in the leaderboard,
// returns an object containing the ranking number for each usrId and the other user info.
// This function assumes that leaderboardEntries is sorted from highest to lowest scoring user.
const CreateLeaderboardGridRows = (leaderboardEntries: LeaderboardEntryWithProfile[]): Array<LeaderboardGridRow> => {
  const navigate = useNavigate();
  // type used in this function to represent the ranking and score of each user in the leaderboard.
  type RankingEntry = {
    rank: number;
    points: number;
  };

  const usernameToRankingNumbers = new Map<string, RankingEntry>();

  // If two users have the same XP value, they should have the same rank. Otherwise, each
  // user should be ranked one lower than the previous user.
  // Walk through the leaderboard and calculate the ranking for each user.

  let currentRank = 1;
  let previousXP = leaderboardEntries[0].xP;

  leaderboardEntries.forEach((value, index: number) => {
    if (previousXP > value.xP) {
      currentRank += 1;
    }

    if (!value.username) {
      //For deleted users, we'll create a prefix + index dummy username.
      value.username = DELETED_USER + index;
    }

    usernameToRankingNumbers.set(value.username, {
      rank: currentRank,
      points: value.xP,
    });

    previousXP = value.xP;
  });

  // This is a map of user IDs (eg. auth0|63168bf92dc9843a4f86049a) to information about that user.
  const gridRows = new Array<LeaderboardGridRow>();
  leaderboardEntries.forEach((leaderboardEntry, index: number) => {
    let username = leaderboardEntry.username;
    if (!username) {
      //For deleted users, we set the user to a prefix + index dummy username,
      //as it's the also the same in usernameToRankingNumbers
      username = DELETED_USER + index;
    }
    const userRankingEntry = usernameToRankingNumbers.get(username);
    if (!userRankingEntry) {
      const errorMessage = 'Missing user rank data.';
      navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.ERROR + COMMON.ERROR + '/' + errorMessage);
    } else {
      // Fill in this entry in the map with the data for this user.
      gridRows.push({
        id: username,
        rank: userRankingEntry.rank,
        username: username.includes(DELETED_USER) ? DELETED_USER_DISPLAY_NAME : username,
        institution: leaderboardEntry.institution ?? EMPTY_STRING,
        points: userRankingEntry.points,
      });
    }
  });

  return gridRows;
};

const PAGE_SIZE = 15;

const columns: GridColDef[] = [
  { field: 'rank', headerName: LEADERBOARD.RANK, width: 100, sortable: true, filterable: true },
  { field: 'username', headerName: LEADERBOARD.USERNAME, width: 300, sortable: true, filterable: true },
  { field: 'institution', headerName: LEADERBOARD.INSTITUTION, width: 300, sortable: true, filterable: true },
  { field: 'points', headerName: LEADERBOARD.EXPERIENCE_POINTS, width: 200, sortable: true, filterable: true },
];

const LeaderboardGrid = ({ leaderboardEntries }: LeaderboardGridProps) => {
  if (leaderboardEntries.length === 0) return <></>;

  const gridRows = CreateLeaderboardGridRows(leaderboardEntries);

  return (
    <Box height={1000}>
      <DataGrid
        rows={gridRows}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'rank', sort: 'asc' }],
          },
        }}
        getRowId={(row) => row.id}
      />
    </Box>
  );
};

export default LeaderboardGrid;

import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { appTheme } from './Themes/theme';
import SplashContext, { SplashContextType } from 'Context/SplashContext';
import { useState } from 'react';
import SplashScreen from 'Screens/SplashScreen';
import { SnackbarUtilsConfigurator } from 'Util/SnackbarUtilsConfigurator';
import { LayoutPage } from 'Screens/Layout/LayoutPage';
import { ConfirmProvider } from 'material-ui-confirm';

function App() {
  const [showSplash, setShowSplash] = useState<boolean>(true);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <ConfirmProvider>
        <SplashContext.Provider
          value={
            {
              showSplash: showSplash,
              setShowSplash: setShowSplash,
            } as SplashContextType
          }
        >
          {showSplash && (
            <Box position="fixed" width="100vw" height="100vh" zIndex={100}>
              <SplashScreen />
            </Box>
          )}
          <SnackbarUtilsConfigurator />
          <Router>
            <LayoutPage />
          </Router>
        </SplashContext.Provider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;

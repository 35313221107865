import { useAuth0 } from '@auth0/auth0-react';
import SplashContext from 'Context/SplashContext';
import { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from './RouteKeys';
import UsersPage from 'Screens/Users/UsersPage';
import LeaderboardPage from 'Screens/Leaderboard/LeaderboardPage';
import UserDetailsPage from 'Screens/Users/UserDetailsPage';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import ErrorPage from 'Screens/Error/ErrorPage';
import { ERRORS } from 'Constants/keys';
import { CircularProgress } from '@mui/material';

interface DecodedTokenFields extends JwtPayload {
  scope: string;
}

const APP_ADMINISTER_SCOPE = 'app:administer';

export const PrivateRoutes = () => {
  const { isLoading: auth0Loading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const splashContext = useContext(SplashContext);

  const [unauthorized, setUnauthorized] = useState<boolean>(false);

  useEffect(() => {
    splashContext?.setShowSplash(auth0Loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Loading]);

  useEffect(() => {
    async function checkAndLogin() {
      if (auth0Loading || isAuthenticated) {
        return;
      }

      loginWithRedirect({});
    }

    checkAndLogin();
  }, [auth0Loading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    async function checkAdminPermission() {
      const token = await getAccessTokenSilently();
      console.log(token);
      const decoded: DecodedTokenFields = jwt_decode(token);
      console.log(decoded);

      setUnauthorized(!decoded.scope.includes(APP_ADMINISTER_SCOPE));
    }

    if (isAuthenticated) {
      checkAdminPermission();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently]);

  if (unauthorized) {
    return (
      <ErrorPage errorTitle={ERRORS.UNAUTHORIZED_ACCESS} errorDescription={ERRORS.UNAUTHORIZED_ACCESS_DESCRIPTION} />
    );
  }

  if (!isAuthenticated) {
    return <CircularProgress />;
  }

  return (
    <Routes>
      <Route path={ROUTE_PATHS.WILDCARD} element={<LeaderboardPage />} />
      <Route path={ROUTE_PATHS.LEADERBOARD} element={<LeaderboardPage />} />
      <Route path={ROUTE_PATHS.USERS} element={<UsersPage />} />
      <Route path={ROUTE_PATHS.USERS_USERID} element={<UserDetailsPage />} />
      <Route path={ROUTE_PATHS.ERROR_TITLE_DESCRIPTION} element={<ErrorPage />} />
    </Routes>
  );
};

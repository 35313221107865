export const COMMON = {
  BACK: 'Back',
  YES: 'Yes',
  NO: 'No',
  ERROR: 'Error',
};

export const ERRORS = {
  UNAUTHORIZED_ACCESS: 'Unauthorized Access',
  UNAUTHORIZED_ACCESS_DESCRIPTION: 'You do not have permission to access the Admin Portal',
};

export const LAYOUT = {
  ADMIN_PORTAL: 'Hologic Cytology Challenge Admin Portal',
  LEADERBOARD: 'Leaderboard',
  USERS: 'Users',
  LOGOUT: 'Logout',
};

export const LEADERBOARD = {
  DATE: 'Date',
  RANK: 'Rank',
  USERNAME: 'Username',
  INSTITUTION: 'Institution',
  EXPERIENCE_POINTS: 'Experience Points',
  EMPTY_LEADERBOARD: 'Leaderboard is empty for selected date',
};

export const USERS = {
  ID: 'ID',
  USERNAME: 'Username',
  EMAIL: 'Email',
  INSTITUTION: 'Institution',
};

export const USER_DETAILS = {
  ID: 'User ID',
  EMAIL: 'Email',
  PROFILE: 'Profile',
  ACHIEVEMENTS: 'Achivements',
  PROGRESS: 'Progress',
  RESET: 'Reset Game Data',
  RESET_PROMPT_TITLE: 'Are you sure?',
  RESET_PROMPT: 'Reset will permanently delete the current achivements and progress for the user.',
  RESET_CONFIRMATION_TITLE: 'Game data reset',
  RESET_CONFIRMATION: 'Game data will be reset when then the user next logs in to game.',
  DELETE: 'Delete User',
  DELETE_PROMPT_TITLE: 'Are you sure?',
  DELETE_PROMPT: "Delete will de-identify the user's game data and delete the user's Auth0 account.",
  DELETE_CONFIRMATION_TITLE: 'User deleted',
  DELETE_CONFIRMATION: "The user's game data has been de-identifid and Auth0 account has been deleted.",
  DELETE_PARTIAL_CONFIRMATION_TITLE: 'User partially deleted',
  DELETE_PARTIAL_CONFIRMATION:
    "The user's game data has been de-identified but the Auth0 account could not be deleted.",
  DELETE_ERROR_CONFIRMATION: "Unable to de-identify the user's game data or delete the user's Auth0 account",
};

export const USER_PROFILE = {
  USERNAME: 'Username',
  INSTITUTION: 'Institution',
  LAST_LOGIN: 'Last Login',
  AVATAR_VARIANT: 'Avatar Variant',
  AVATAR_URL: 'Avatar URL',
};

export const USER_ACHIEVEMENTS = {
  EXPERIENCE_POINTS: 'Experience Points',
  LONGEST_STREAK: 'Longest Streak',
  CURRENT_STREAK: 'Current Streak',
  LAST_LESSON_COMPLETION: 'Last Lesson Completion',
  PERFECT_LESSONS: 'Perfect Lessons',
  BADGE_TYPE: 'Badge Type',
  LEVEL: 'Level',
};

export const USER_PROGRESS = {
  GAME_COMPLETED: 'Game Completed',
  UNIT_ID: 'Unit ID',
  TOPIC_ID: 'Topic ID',
  TOPIC_NAME: 'Topic',
  UNIT_COMPLETED: 'Unit Completed',
  LESSONS_COMPLETED: 'Lessons Completed',
  LOCKED: 'Locked',
};

export const SIGN_UP_DIRECTIVE = 'signUp';
export const BLANK_URL = 'DOES_NOT_EXIST';

export const DELETED_USER = 'DeletedUser';
export const DELETED_USER_DISPLAY_NAME = 'Deleted User';
export const EMPTY_STRING = '';

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PLAYGROUND: 'playground',
};

import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { COMMON, LEADERBOARD } from 'Constants/keys';
import { useDailyLeaderboardByDateQuery } from 'graphql/generated/graphql';
import LeaderboardGrid from './LeaderboardGrid';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// The leaderboard page. The implementation makes the following assumptions:
//    The list of leaderboard entries returned by the backend is sorted from highest to lowest experience.
const LeaderboardPage = () => {
  const navigate = useNavigate();

  dayjs.extend(utc);

  const [utcDateTimeString, setUtcDateTimeString] = React.useState<string | null>(dayjs().utc().format());

  // Get the leaderboard
  // Use network-only fetchPolicy to ensure it is up to date when the page is loaded.
  const {
    data: dailyLeaderboard,
    loading: dailyLeaderboardLoading,
    error: dailyLeaderboardError,
    refetch: refetchDailyLeaderboard,
  } = useDailyLeaderboardByDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: -1, // set limit to -1 to retrieve all entries }
      utcDateTime: utcDateTimeString,
    },
  });

  useEffect(() => {
    if (dailyLeaderboardError) {
      navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.ERROR + COMMON.ERROR + '/' + dailyLeaderboardError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyLeaderboardError]);

  if (dailyLeaderboardLoading) {
    return <CircularProgress />;
  }

  const updateDailyLeaderboard = (newUtcDateTime: string | null) => {
    refetchDailyLeaderboard({
      limit: -1, // set limit to -1 to retrieve all entries }
      utcDateTime: newUtcDateTime,
    });
  };

  let leaderboardComponent;

  if (dailyLeaderboard?.dailyLeaderboardByDate?.entries) {
    // If we've received a leaderboard, create that component.
    leaderboardComponent = <LeaderboardGrid leaderboardEntries={dailyLeaderboard?.dailyLeaderboardByDate?.entries} />;
  } else {
    // Otherwise, show the text for when the leaderboard is empty.
    leaderboardComponent = <Typography variant="h6"> {LEADERBOARD.EMPTY_LEADERBOARD}</Typography>;
  }

  return (
    <Box p={2}>
      <Stack
        direction="row"
        alignContent="center"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" mr={3}>
          {LEADERBOARD.DATE}{' '}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            value={utcDateTimeString}
            onChange={(newValue) => {
              setUtcDateTimeString(newValue);
              updateDailyLeaderboard(newValue);
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Box mt={2}>{leaderboardComponent}</Box>
    </Box>
  );
};

export default LeaderboardPage;

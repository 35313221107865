import { createTheme, PaletteColorOptions, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColorOptions;
    lesson: PaletteColorOptions;
    white: PaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    lesson: PaletteColorOptions;
    white: PaletteColorOptions;
  }

  interface PaletteColor {
    tertiary?: string;
    lesson?: string;
    white?: string;
  }

  interface SimplePaletteColorOptions {
    tertiary?: string;
    contrastText?: string;
    lesson?: string;
    white?: string;
    two?: string;
    three?: string;
    max?: string;
    title?: string;
    locked?: string;
    badgeUnlocked?: string;
    achievementPanelBackground?: string;
    streakCenter?: string;
    streakGrey?: string;
  }

  interface TypographyVariants {
    title: React.CSSProperties;
    captionBold?: React.CSSProperties;
    exerciseTitle?: React.CSSProperties;
    lessonEndTitle?: React.CSSProperties;
    exerciseResult?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    captionBold?: React.CSSProperties;
    exerciseTitle?: React.CSSProperties;
    lessonEndTitle?: React.CSSProperties;
    exerciseResult?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    captionBold: true;
    exerciseTitle: true;
    lessonEndTitle: true;
    exerciseResult: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    lesson: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    mob: true; //mobile
    tab: true; //tablet
    lap: true; //laptop
    des: true; //desktop
    xl: true;
  }
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mob: 321,
      tab: 640,
      lap: 1024,
      des: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#73287B',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
        },
      },
    },
  },
  palette: {
    error: {
      main: '#D24E4A',
      light: '#FBEDEC',
      dark: '#D24E4A',
    },
    success: {
      main: '#18AE0D',
      light: '#E7F7E6',
      dark: '#18AE0D',
    },
    action: {
      disabledBackground: '#AF3DB233',
      disabled: 'AF3DB20D',
    },
    primary: {
      main: '#AF3DB2',
      dark: '#73287B',
      light: '#AF3DB20D',
      contrastText: 'FFFFFF',
    },
    secondary: {
      main: '#118BAE',
      contrastText: '#68719C',
    },
    tertiary: {
      main: '#118BAE',
      dark: '#0F206C',
      contrastText: '#707070',
    },
    lesson: {
      main: '#D24E4A',
      dark: '#999999',
      light: '#B8E2FF',
      two: '#F9E05B',
      three: '#18AE0D',
      max: '#5CB4F1',
      title: '#D5BED7',
      locked: '#E6E6E6',
      badgeUnlocked: '#C79C3D',
      achievementPanelBackground: '#D5BED71A',
      streakCenter: '#EE8760',
      streakGrey: '#BBBBBB',
    },
    text: {
      primary: '#000000',
      secondary: '#3A3A3A',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  spacing: 4,
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: '500',
      alignSelf: 'center',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '500',
      alignSelf: 'center',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: '500',
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'regular',
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: 'light',
      fontStyle: 'italic',
      alignSelf: 'center',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 'regular',
      alignSelf: 'center',
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2rem',
      color: '#0F206C',
      alignSelf: 'left',
      textTransform: 'capitalize',
    },
    lessonEndTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#3A3A3A',
    },
    exerciseTitle: {
      fontSize: '1.25rem',
      fontWeight: '500',
      color: '#3A3A3A',
    },
    exerciseResult: {
      fontSize: '0.875rem',
      alignSelf: 'center',
      fontWeight: 'regular',
    },
    captionBold: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      alignSelf: 'left',
    },
  },
});

export const appTheme = responsiveFontSizes(theme);

import { Box, Button, CircularProgress, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { COMMON, USER_DETAILS } from 'Constants/keys';
import {
  useMarkPendingResetMutation,
  useDeleteAnyUserMutation,
  User,
  useUserByIdQuery,
  DeleteUserResult,
} from 'graphql/generated/graphql';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { decodeUserId } from 'Util/userId';
import UserProfileTab from './UserProfileTab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UserAchievementsTab from './UserAchievementsTab';
import UserProgressTab from './UserProgressTab';
import UserField from './UserField';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'material-ui-confirm';
import { ROUTE_PATHS } from 'Routes/RouteKeys';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const UserDetailsPage = () => {
  const params = useParams();

  const [value, setValue] = useState(0);
  const [user, setUser] = useState<User>();
  const [markPendingReset] = useMarkPendingResetMutation({});
  const [deleteAnyUser] = useDeleteAnyUserMutation({});
  const navigate = useNavigate();
  const confirm = useConfirm();

  let userId = '';
  if (params.userId) {
    userId = params.userId;
  }

  const {
    data: userData,
    error: userError,
    loading: userLoading,
    refetch: refetchUserData,
  } = useUserByIdQuery({ skip: userId === '', variables: { userId: userId } });

  useEffect(() => {
    if (!userData) return;

    if (userData.userById) {
      setUser(userData.userById);
    }

    if (userError) {
      navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.ERROR + COMMON.ERROR + '/' + userError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userError]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleReset = () => {
    confirm({
      title: USER_DETAILS.RESET_PROMPT_TITLE,
      description: USER_DETAILS.RESET_PROMPT,
      confirmationText: COMMON.YES,
      cancellationText: COMMON.NO,
    })
      .then(async () => {
        // Call mutation to mark user as pending reset then show confirmation prompt
        await markPendingReset({ variables: { userId: userId } }).then(async (response) => {
          if (response?.data?.markPendingReset) {
            confirm({
              title: USER_DETAILS.RESET_CONFIRMATION_TITLE,
              description: USER_DETAILS.RESET_CONFIRMATION,
              cancellationText: null,
            });
          }
        });
      })
      .catch(() => {
        // Nothing to do
      });
  };

  const handleDelete = () => {
    confirm({
      title: USER_DETAILS.DELETE_PROMPT_TITLE,
      description: USER_DETAILS.DELETE_PROMPT,
      confirmationText: COMMON.YES,
      cancellationText: COMMON.NO,
    })
      .then(async () => {
        // Call mutation to mark user as pending reset then show confirmation prompt
        await deleteAnyUser({ variables: { userId: userId } }).then(async (response) => {
          switch (response?.data?.deleteAnyUser as DeleteUserResult) {
            case DeleteUserResult.OnlyDeidentifyDeleted:
              confirm({
                title: USER_DETAILS.DELETE_PARTIAL_CONFIRMATION_TITLE,
                description: USER_DETAILS.DELETE_PARTIAL_CONFIRMATION,
                cancellationText: null,
              });
              break;
            case DeleteUserResult.Success:
              confirm({
                title: USER_DETAILS.DELETE_CONFIRMATION_TITLE,
                description: USER_DETAILS.DELETE_CONFIRMATION,
                cancellationText: null,
              });
              break;
            default:
              confirm({
                title: COMMON.ERROR,
                description: USER_DETAILS.DELETE_ERROR_CONFIRMATION,
                cancellationText: null,
              });
          }
          refetchUserData();
        });
      })
      .catch(() => {
        // Nothing to do
      });
  };

  if (userLoading) return <CircularProgress />;

  return (
    <Box>
      <Stack direction="row" ml={2} mb={4} pt={2} sx={{ alignItems: 'center' }}>
        <IconButton aria-label="delete" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" ml={2}>
          {COMMON.BACK}
        </Typography>
      </Stack>

      <Stack ml={3}>
        <UserField fieldName={USER_DETAILS.ID} value={decodeUserId(params.userId)} />
        <UserField fieldName={USER_DETAILS.EMAIL} value={user?.email?.toString()} />
      </Stack>

      <Box ml={3}>
        <Button variant="contained" startIcon={<RefreshIcon />} onClick={handleReset} sx={{ width: 200 }}>
          {USER_DETAILS.RESET}
        </Button>
      </Box>

      <Box ml={3} mt={1}>
        <Button variant="contained" startIcon={<DeleteIcon />} onClick={handleDelete} sx={{ width: 200 }}>
          {USER_DETAILS.DELETE}
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 1, my: 1 }}>
        <Tabs value={value} sx={{ mt: 2 }} onChange={handleChange}>
          <Tab label={USER_DETAILS.PROFILE} {...a11yProps(0)} />
          <Tab label={USER_DETAILS.ACHIEVEMENTS} {...a11yProps(1)} />
          <Tab label={USER_DETAILS.PROGRESS} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <UserProfileTab profile={user?.profile} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserAchievementsTab achievements={user?.achievements} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserProgressTab progress={user?.gameProgress} />
      </TabPanel>
    </Box>
  );
};

export default UserDetailsPage;

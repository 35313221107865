export function getConfig(): Config {
  const config = {} as Config;
  config.auth0Audience = process.env.REACT_APP_AUDIENCE ?? '';
  config.auth0ClientId = process.env.REACT_APP_CLIENT_ID ?? '';
  config.auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
  config.graphQLBackend = process.env.REACT_APP_GRAPHQL_BACKEND ?? '';
  config.reactAppVersion = process.env.REACT_APP_VERSION ?? '0.0.0';
  config.bucket = process.env.REACT_APP_S3BUCKET ?? 'exmachina-assets';
  config.region = process.env.AWS_REGION ?? 'us-west-2';
  config.roleArn =
    process.env.REACT_APP_PUBLIC_ROLE_ARN ?? 'arn:aws:iam::975063973411:role/auth0-riberasolutions-dev-s3';
  return config;
}

export interface Config {
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  graphQLBackend: string;
  reactAppVersion: string;
  bucket: string;
  region: string;
  roleArn: string;
}
